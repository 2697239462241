import React from 'react';
import classes from './Accordion.module.scss';

export interface AccordionContentProps {
    children: JSX.Element | JSX.Element[];
}

const AccordionContent: React.FC<AccordionContentProps> = ({
    children,
}) => {
    return (
        <div className={classes.accordionContent}>
            {children}
        </div>
    );
};

export default AccordionContent;

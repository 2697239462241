export enum DataState {
    STATIC = 'STATIC',
    LOADING = 'LOADING',
    LOADED = 'LOADED',
    ERROR = 'ERROR',
}

export interface BlogPostItemProps {
    id: number,
    slug: string,
    title: string;
    excerpt: string;
    date: string;
    thumbnail: string;
}

export interface BlogPostProps {
    title: string;
    content: string;
    thumbnail: string;
    date: string;
}

export interface BlogInfoInterface {
    status: DataState;
    posts: BlogPostItemProps[] | null;
}

import React from 'react';
import Wrapper from "../../components/Wrapper/Wrapper";
import {BlogItem} from "../../components/BlogItem/BlogItem";
import {DataState} from "../../interfaces";
import classes from './Blog.module.scss';
import Container from "../../components/Container/Container";
import Title from "../../components/Title/Title";
import {Spinner} from "../../components/Spinner/Spinner";
import {ErrorAlert} from "../../components/ErrorAlert/ErrorAlert";
import {useContextState} from "../../ContextState";

const Blog: React.FC = () => {
    const { blog } = useContextState();
    const { status: dataStatus, posts } = blog;

    return (
        <Wrapper classNameProps={classes.root}>
            <Container>
                <>
                    <Title variant={'h1-small'}>Новини</Title>
 
                    {dataStatus === DataState.LOADING && (
                        <Spinner className={classes.spinner}/>
                    )}

                    {dataStatus === DataState.LOADED && (
                        <div className={classes.posts}>
                            {posts?.map(post => (
                                <div key={`post-${post.id}`} className={classes.post}>
                                    <BlogItem {...post} />
                                </div>
                            ))}
                        </div>
                    )}

                    {dataStatus === DataState.ERROR && (
                        <ErrorAlert message="Неочаквана грешка при зареждането на постове. Моля опитайте по-късно."/>
                    )}
                </>
            </Container>

            <figure className={classes.circleBg}/>
        </Wrapper>
    );
}

export default Blog;

import React from 'react';
import classes from './TextBar.module.scss';
import {ReactComponent as CommunicationSvg} from "../../media/images/svg/communication.svg";

interface TextBarProps {
    children: JSX.Element | string;
}

const TextBar: React.FC<TextBarProps> = ({
    children
}) => {
    return (
        <div className={classes.root}>
            <CommunicationSvg />

            <h3>{children}</h3>
        </div>
    );
};

export default TextBar;

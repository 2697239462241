import React from 'react';
import classes from './Title.module.scss';
import {joinClassNames} from "../../Utils";

interface TitleProps {
    variant?: 'h1' | 'h1-small' | 'h2' | 'h4';
    children: JSX.Element | string | (JSX.Element | string)[];
    color?: 'white' | 'cyan';
    classNameProps?: string;
}

const Title: React.FC<TitleProps> = ({
    variant = 'h2',
    children,
    color = 'cyan',
    classNameProps = '',
}) => {
    const classNames = [
        classes.lineBorder,
        color === 'cyan' ? '' : classes.white,
        variant === 'h1-small' ? classes.smallTitle : '',
        classNameProps,
    ];

    return (
        <>
            {variant === 'h1' && <h1 className={joinClassNames(classNames)}>{children}</h1>}
            {variant === 'h1-small' && <h1 className={joinClassNames(classNames)}>{children}</h1>}
            {variant === 'h2' && <h2 className={joinClassNames(classNames)}>{children}</h2>}
            {variant === 'h4' && <h4 className={joinClassNames(classNames)}>{children}</h4>}
        </>
    );
};

export default Title;

import React from 'react';
import classes from './BoxAccordions.module.scss';
import Accordion, {AccordionProps} from "../Accordion/Accordion";
import AccordionContent from "../Accordion/AccordionContent";
import {AccordionsContentModel} from "../../pages/Courses/CourseAccordionsContent";
import {CSSTransition} from "react-transition-group";
import animationClasses from "./box-accordion.animations.module.scss";
import {setForWindowSize} from "../../Utils";
import {breakpoints} from "../../App-vars";
import {useWindowSize} from "../../hooks";

interface BoxAccordionsProps {
    accordions: AccordionProps[];
    accordionsContent: AccordionsContentModel[];
    activeAccordion: string;
    toggleAccordion: (name: string) => void;
}

const BoxAccordions: React.FC<BoxAccordionsProps> = ({
    accordions,
    accordionsContent,
    activeAccordion,
    toggleAccordion,
}) => {
    const windowSize = useWindowSize();

    return (
        <div className={classes.root}>
            <div className={classes.boxes}>
                {accordions.map((accordionProps, idx) => (
                    <React.Fragment key={`accordion-item-${idx}`}>
                        <Accordion
                            {...accordionProps}
                            onClick={toggleAccordion}
                            accordionActive={activeAccordion === accordionProps.name}
                            inactive={!accordionsContent[idx]?.content}
                        />

                        {setForWindowSize(windowSize.width, 0, breakpoints.sm) && (
                            <CSSTransition
                                in={accordionsContent[idx].name === activeAccordion}
                                timeout={500}
                                classNames={animationClasses}
                                unmountOnExit
                            >
                                <AccordionContent>
                                    {accordionsContent[idx].content || <></>}
                                </AccordionContent>
                            </CSSTransition>
                        )}
                    </React.Fragment>
                ))}
            </div>

            {setForWindowSize(windowSize.width, breakpoints.sm, 0) && (
                <div className={classes.infoBox}>
                    {accordionsContent.map((accordionContent, idx) => (
                        <CSSTransition
                            key={`accordion-content-${idx}`}
                            in={accordionContent.name === activeAccordion}
                            timeout={500}
                            classNames={animationClasses}
                            unmountOnExit
                        >
                            {accordionContent.content && (
                                <AccordionContent
                                    key={`accordion-content-item-${idx}`}
                                >
                                    {accordionContent.content}
                                </AccordionContent>
                            )}
                        </CSSTransition>
                    ))}
                </div>
            )}
        </div>
    );
};

export default BoxAccordions;

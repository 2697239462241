import React from 'react';
import classes from './Consultations.module.scss';
import medicalRoomImage from './../../media/images/medical-room.webp';
import Wrapper from "../../components/Wrapper/Wrapper";
import Title from "../../components/Title/Title";
import TextBar from "../../components/TextBar/TextBar";

const consultationsList = [
    'Опасни химични вещества и смеси',
    'Програми за минимизиране на риска при работа',
    'Противопожарна безопасност',
    'Съоръжения с повишена опасност',
    'Ергономия на работно място',
    'Знаци и табели по безопасност',
    'Ел.безопасност',
    'Разработване на инструкции по безопасност',
    'Подпомагане на работодателите при изпълнението на нормативно установените изисквания, свързани със здравословните и безопасни условия на труд.',
    'Подпомагане на работодателите при разработването на правила, норми и инструкции за осигуряване на здраве и безопасност при работа.',
    'Участие в определяне на работни места за трудоустрояване на лица с намалена трудоспособност и бременни жени',
];

const Consultations = () => {
    return (
        <Wrapper
            backgroundUrl={medicalRoomImage}
            classNameProps={classes.root}
        >
            <div className={classes.content}>
                <div className={classes.info}>
                    <Title variant={'h1-small'}>Консултации</Title>
                </div>

                <ul className={classes.consultationsList}>
                    {consultationsList.map((consultation, idx) => (
                        <li key={`consultation-${idx}`}>
                            <TextBar>{consultation}</TextBar>
                        </li>
                    ))}
                </ul>
            </div>
        </Wrapper>
    );
}

export default Consultations;

import React from 'react';
import Wrapper from "../../components/Wrapper/Wrapper";
import classes from './NotFound.module.scss';
import Button from "../../components/Button/Button";

const NotFound: React.FC = (props) => {
    return (
        <Wrapper classNameProps={classes.root}>
            <div className={classes.container}>
                <h1>404</h1>

                <h4>Страницата не съществува.</h4>

                <Button type="link" url="/">Начало</Button>
            </div>
        </Wrapper>
    );
}

export default NotFound;

import React from 'react';
import useWindowSize from "@rooks/use-window-size";
import { useHistory } from "react-router-dom";
import ParticleImage, {
    ParticleOptions,
    Vector,
    forces,
    ParticleForce
} from "react-particle-image";
import classes from './HomeLayout.module.scss';
import textLogoSvg from './../../media/images/svg/text-logo.svg';
import subscriptionSvg from './../../media/images/svg/subscription.svg';
import rateSvg from './../../media/images/svg/rate.svg';
import bookSvg from "../../media/images/svg/book.svg";
import documentSvg from './../../media/images/svg/document.svg';
import {ReactComponent as SafetyHelmetSvg} from "../../media/images/svg/safety-helmet.svg";
import {ReactComponent as SideImageSvg} from "../../media/images/svg/home-background.svg";
import {ReactComponent as SideImageMdSvg} from "../../media/images/svg/home-background-md.svg";
import {Locations} from "../../App";
import {ServicesEnum} from "../../pages/Services/ServicesContent";

const makeParticleOptions = (isMobile?: boolean) => {
    const options: ParticleOptions = {
        filter: ({ x, y, image }) => {
            // Get pixel
            const pixel = image.get(x, y);
            // Make a particle for this pixel if blue > 50 (range 0-255)
            return pixel.b > 50;
        },
        color: ({ x, y, image }) => "#fff",
        radius: () => isMobile ? 0.5 : 1.5,
        mass: () => 40,
        friction: () => 0.15,
        initialPosition: ({ canvasDimensions }) => {
            return new Vector(canvasDimensions.width / 2, canvasDimensions.height / 2);
        }
    };

    return options;
}

const motionForce = (x: number, y: number): ParticleForce => {
    return forces.disturbance(x, y, 30);
};

const imagesParticleOptions: ParticleOptions = {
    filter: ({ x, y, image }) => {
        // Get pixel
        const pixel = image.get(x, y);
        // Make a particle for this pixel if blue > 50 (range 0-255)
        return pixel.b > 50;
    },
    color: ({ x, y, image }) => "#fff",
    radius: () => 0.5,
    mass: () => 40,
    friction: () => 0.15,
    initialPosition: ({ canvasDimensions }) => {
        return new Vector(canvasDimensions.width / 2, canvasDimensions.height / 2);
    }
}

const imagesMotionForce = (x: number, y: number): ParticleForce => {
    return forces.disturbance(x, y, 5);
};

const serviceImages = [
    {
        label: 'Абонаментно обслужване',
        image: subscriptionSvg,
        serviceName: ServicesEnum.Subscription,
    },
    {
        label: 'Оценка на риска',
        image: rateSvg,
        serviceName: ServicesEnum.Grade,
    },
    {
        label: 'Организация на инструктажите',
        image: bookSvg,
        serviceName: ServicesEnum.Instructions,
    },
    {
        label: 'Документи по ЗБУТ във фирмата',
        image: documentSvg,
        serviceName: ServicesEnum.Documents,
    },
];

export const HomeLayout: React.FC = (props) => {
    const history = useHistory();
    const { innerWidth, innerHeight } = useWindowSize();
    const isMobileBreakpoint = innerWidth && innerWidth < 768;

    const handleCanvasScaling = () => {
        if(!innerWidth) return 0.8;

        if(innerWidth > 1600 && innerWidth < 1800) {
            return 0.65;
        } else if(innerWidth < 360) {
            return 0.25;
        } else if(innerWidth < 414) {
            return 0.3;
        } else if(innerWidth < 768) {
            return 0.3;
        } else if(innerWidth < 1024) {
            return 0.4;
        } else if(innerWidth < 1280) {
            return 0.5;
        } else if(innerWidth <= 1600) {
            return 0.6;
        }

        return 0.8;
    }

    const handleMainCanvasWidth = () => {
        if(!innerWidth) return innerWidth;

        if(innerWidth > 1600) {
            return innerWidth - 766;
        } else if(innerWidth < 1024) {
            return innerWidth - 230;
        } else if(innerWidth < 1280) {
            return innerWidth - 340;
        } else if(innerWidth < 1400) {
            return innerWidth - 400;
        } else if(innerWidth <= 1600) {
            return innerWidth - 570;
        }
    }

    const displaySideImage = () => {
        if(!innerWidth) return;

        if(innerWidth >= 1400) {
            return <SideImageSvg className={classes.sideImage} />;
        } else if(innerWidth < 1400) {
            return <SideImageMdSvg className={classes.sideImage} />
        }
    }

    const redirectToService = (serviceName: string) => {
        history.push(`${Locations.Services}?name=${serviceName}`);
    }

    const ServiceLabel = (props: {label: string}) =>
        <span className={classes.label}>{props.label}</span>


    return (
        <div className={classes.wrapper}>
            <div className={classes.container}>
                <div className={`${classes.backgroundSvg} ${classes.backgroundSvgLeft}`}>
                    <SafetyHelmetSvg />
                </div>

                <ParticleImage
                    src={textLogoSvg}
                    className={classes.mainCanvas}
                    width={Number(!isMobileBreakpoint ? handleMainCanvasWidth() : innerWidth)}
                    height={Number(innerHeight)}
                    scale={handleCanvasScaling()}
                    entropy={0}
                    maxParticles={5000}
                    particleOptions={makeParticleOptions((innerWidth && innerWidth <= 768) || undefined)}
                    mouseMoveForce={motionForce}
                    touchMoveForce={motionForce}
                />

                <div className={classes.canvasImages}>
                    {serviceImages.map((service, idx) => (
                        <div className={classes.canvasImageContainer}>
                            <ParticleImage
                                key={`particleImage-${idx}`}
                                src={service.image}
                                width={isMobileBreakpoint ? 250 : 200}
                                height={isMobileBreakpoint ? 150 : 200}
                                scale={1.5}
                                entropy={5}
                                maxParticles={1500}
                                particleOptions={imagesParticleOptions}
                                mouseMoveForce={imagesMotionForce}
                                touchMoveForce={imagesMotionForce}
                                backgroundColor="transparent"
                                onClick={() => redirectToService(service.serviceName)}
                            />

                            <ServiceLabel label={service.label} />
                        </div>
                    ))}
                </div>
            </div>

            {!isMobileBreakpoint && (
                <div className={classes.sideImageContainer}>
                    {displaySideImage()}
                </div>
            )}
        </div>
    );
}

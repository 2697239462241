import React from 'react';
import classes from './Container.module.scss';
import {joinClassNames} from "../../Utils";

interface ContainerProps {
    children: JSX.Element[] | JSX.Element;
    fullWidth?: boolean;
    classNameProps?: string;
}

const Container: React.FC<ContainerProps> = ({
    children,
    fullWidth= false,
    classNameProps = '',
}) => {
    const classNames = [
        fullWidth ? classes.fullWidth : classes.container,
        classNameProps,
    ];

    return (
        <div
            className={joinClassNames(classNames)}
        >
            {children}
        </div>
    );
};

export default Container;

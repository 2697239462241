import React from 'react';
import classes from './Accordion.module.scss';
import {ReactComponent as PresentationSvg} from "../../media/images/svg/presentation.svg";
import {ReactComponent as ChevronSvg} from "../../media/images/svg/chevron.svg";

export interface AccordionProps {
    title: string;
    onClick?: (name: string) => void;
    accordionActive?: boolean;
    name?: string;
    inactive?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
    title,
    onClick,
    accordionActive,
    name,
    inactive = false,
}) => {
    return (
        <>
            <div
                className={`
                    ${classes.root} 
                    ${accordionActive ? classes.active : ''}
                    ${inactive ? classes.disabled : ''}
                `}
                onClick={onClick && (() => onClick(name ? name : ''))}
            >
                <PresentationSvg />

                <h4>{title}</h4>

                {!inactive && (
                    <button>
                        <ChevronSvg />
                    </button>
                )}
            </div>
        </>
    );
};

export default Accordion;

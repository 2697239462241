import React from 'react';
import classes from './ErrorAlert.module.scss';

interface ErrorProps {
    message: string;
}

export const ErrorAlert: React.FC<ErrorProps> = ({
    message,
}) => {
    return (
        <div className={classes.root}>
            <p>{message}</p>
        </div>
    );
}

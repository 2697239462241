import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {BlogPostProps, DataState} from "../../interfaces";
import {getBlogPostUrl} from "../../App-vars";
import {formattedDate} from "../../Utils";
import Wrapper from "../../components/Wrapper/Wrapper";
import classes from './BlogPost.module.scss';
import {Spinner} from "../../components/Spinner/Spinner";
import {ErrorAlert} from "../../components/ErrorAlert/ErrorAlert";
import Container from "../../components/Container/Container";
import Button from "../../components/Button/Button";
import {Locations} from "../../App";

interface Params {
    slug: string;
}

const BlogPost: React.FC = (props) => {
    const { slug } = useParams<Params>();
    const [dataStatus, setDataStatus] = useState<DataState>(DataState.LOADING);
    const [post, setPost] = useState<BlogPostProps>();

    useEffect(() => {
        fetch(getBlogPostUrl(slug))
            .then(resp => resp.json())
            .then(data => {
                if(data.error) throw new Error('Post does not exist!');

                setPost({
                    title: data.title,
                    content: data.content,
                    thumbnail: data.featured_image,
                    date: formattedDate(data.date),
                });
            }).catch(err => {
                console.error(err)
                setDataStatus(DataState.ERROR);
        })
    }, [slug]);

    useEffect(() => {
        if(!!post) {
            setDataStatus(DataState.LOADED);
        }
    }, [post]);

    const renderPost = ({
        title,
        content,
        thumbnail,
        date
    }: BlogPostProps) => {
        return (
            <div className={classes.blogPost}>
                <h3>{title}</h3>

                {thumbnail && <img src={thumbnail} width={200} alt=""/>}

                <span className={classes.meta}>{date}</span>

                <div dangerouslySetInnerHTML={{__html: content}} className={classes.content} />
            </div>
        )
    }

    return (
        <Wrapper classNameProps={classes.wrapper}>
            <Container classNameProps={classes.container}>
                <div className={classes.containerInner}>
                    {dataStatus === DataState.LOADING && (
                        <Spinner className={classes.spinner} />
                    )}

                    {(dataStatus === DataState.LOADED && !!post) && renderPost(post)}

                    {dataStatus === DataState.ERROR && (
                        <div className={classes.errorContainer}>
                            <ErrorAlert message="Този пост не съществува." />

                            <Button type="link" url={Locations.Blog}>Към новини</Button>
                        </div>
                    )}
                </div>
            </Container>
        </Wrapper>
    );
}

export default BlogPost;

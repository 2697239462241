import {createContext, useContext} from 'react';
import {BlogInfoInterface, DataState} from "./interfaces";

export interface Settings {
    navOpen: boolean;
}

export interface ContextStateType {
    settings: Settings;
    blog: BlogInfoInterface;
    setBlog: (blogInfo: BlogInfoInterface) => void;
    setSettings: (settings: Settings) => void;
}

export const ContextState = createContext<ContextStateType>({
    settings: {
        navOpen: false,
    },
    setSettings: () => null,
    blog: {
        status: DataState.STATIC,
        posts: null,
    },
    setBlog: () => null,
});
export const useContextState = () => useContext(ContextState);

import React from 'react';
import classes from './Wrapper.module.scss';
import { joinClassNames } from "../../Utils";
import Figure from "../Figure/Figure";

interface WrapperProps {
    children: JSX.Element[] | JSX.Element;
    backgroundUrl?: string;
    overlayType?: 'light' | 'dark';
    classNameProps?: string;
    hasFigure?: boolean;
    figureDirection?: 'left' | 'right';
    bgComponent?: JSX.Element;
}

const Wrapper: React.FC<WrapperProps> = ({
    children,
    backgroundUrl,
    overlayType= '',
    classNameProps= '',
    hasFigure = false,
    figureDirection = 'right',
    bgComponent,
}) => {
    const overlay =
        overlayType !== '' ?
            overlayType === 'light'? classes.overlayLight : classes.overlayDark
            : '';
    const classNames = [
        classes.root,
        overlay,
        classNameProps,
    ];

    return (
        <div
            className={joinClassNames(classNames)}
            style={{backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : undefined}}
        >
            <div className={classes.inner}>
                {children}
            </div>

            {hasFigure && <Figure side={figureDirection} />}

            {bgComponent && bgComponent}
        </div>
    );
};

export default Wrapper;

import React from 'react';
import classes from './Spinner.module.scss';

interface SpinnerProps {
    className?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({
    className,
}) => {
    return (
        <div className={`${classes.ldsRing} ${className ? className : ''}`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}


import React, {useEffect, useState} from 'react';
import {Route, Switch, useLocation,} from 'react-router-dom';
import {ContextState} from "./ContextState";
import './App.scss';
import animationClasses from './App.animations.module.scss';
import Nav from "./components/Nav/Nav";
import Home from './pages/Home/Home';
import About from "./pages/About/About";
import Services from './pages/Services/Services';
import Courses from "./pages/Courses/Courses";
import Consultations from "./pages/Consultations/Consultations";
import Contacts from "./pages/Contacts/Contacts";
import Blog from "./pages/Blog/Blog";
import BlogPost from "./pages/BlogPost/BlogPost";
import NotFound from "./pages/NotFound/NotFound";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import classes from './App.module.scss';
import {BlogInfoInterface, BlogPostItemProps, DataState} from "./interfaces";
import {wpBlogsUrl} from "./App-vars";
import {formattedDate} from "./Utils";

export enum Locations {
    Home = '/',
    About = '/about',
    Services = '/services',
    Courses = '/courses',
    Consultations = '/consultations',
    Blog = '/blog',
    Contacts = '/contacts',
}

const App = () => {
    const [settings, setSettings] = useState({navOpen: false});
    const [blogInfo, setBlogInfo] = useState<BlogInfoInterface>({
        status: DataState.STATIC,
        posts: null,
    });
    const location = useLocation();

    useEffect(() => {
        setSettings({navOpen: false});
    }, [location.pathname]);

    useEffect(() => {
        if(
            blogInfo.status !== DataState.LOADING &&
            blogInfo.status !== DataState.ERROR &&
            !blogInfo.posts
        ) {
            setBlogInfo({
                status: DataState.LOADING,
                posts: blogInfo.posts,
            });

            fetch(wpBlogsUrl)
                .then(resp => resp.json())
                .then(data => {
                    if (data.error) throw new Error();

                    const posts: BlogPostItemProps[] = data
                        .posts
                        .map((post: any) => ({
                            id: post.ID,
                            slug: post.slug,
                            title: post.title,
                            excerpt: post.excerpt,
                            date: formattedDate(post.date),
                            thumbnail: post.featured_image,
                        }));

                    setBlogInfo({
                        status: !!posts.length ? DataState.LOADED : DataState.ERROR,
                        posts: !!posts.length ? posts : null,
                    });
                }).catch(err => {
                console.error(err)
                setBlogInfo({
                    status: DataState.ERROR,
                    posts: null,
                });
            })
        }
    }, [blogInfo])

    const contextState = {
        settings,
        setSettings,
        blog: blogInfo,
        setBlog: setBlogInfo,
    };

    return (
        <>
            <ContextState.Provider value={contextState}>
                <Nav/>

                <TransitionGroup component="div" className={`${classes.app} ${settings.navOpen ? classes.appMoved : ''}`}>
                    <CSSTransition
                        key={location.pathname}
                        timeout={600}
                        classNames={animationClasses}
                        mountOnEnter={false}
                        unmountOnExit
                    >
                        <Switch location={location}>
                            <Route exact path={Locations.Home} component={Home}/>
                            <Route exact path={Locations.About} component={About}/>
                            <Route exact path={Locations.Services} component={Services}/>
                            <Route exact path={Locations.Courses} component={Courses}/>
                            <Route exact path={Locations.Consultations} component={Consultations}/>
                            <Route exact path={Locations.Contacts} component={Contacts}/>
                            <Route exact path={Locations.Blog} component={Blog}/>
                            <Route exact path={`${Locations.Blog}/:slug`} component={BlogPost}/>
                            <Route component={NotFound}/>
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            </ContextState.Provider>
        </>
    );
}

export default App;

import React from 'react';
import classes from './Services.module.scss';
import servicesBgImage from "../../media/images/svg/services-bg.svg";
import constructionImage from './../../media/images/construction-bg.webp';
import Wrapper from "../../components/Wrapper/Wrapper";
import Title from "../../components/Title/Title";
import ServicesContent from "./ServicesContent";

const Services = () => {
    return (
        <Wrapper
            classNameProps={classes.root}
            bgComponent={<figure className={classes.image} style={{backgroundImage: `url(${constructionImage})`}} />}
        >
            <div className={classes.container}>
                <div className={classes.content}>
                    <div className={classes.contentInner}>
                        <Title
                            variant={'h1-small'}
                            classNameProps={classes.pageTitle}
                        >
                            Услуги
                        </Title>

                        <ServicesContent/>
                    </div>

                    <figure style={{backgroundImage: `url(${servicesBgImage})`}} />
                </div>
            </div>
        </Wrapper>
    );
}

export default Services;

import React from 'react';
import classes from './About.module.scss';
import backgroundImage from './../../media/images/about-bg.webp';
import Wrapper from "../../components/Wrapper/Wrapper";
import Title from "../../components/Title/Title";
import {ReactComponent as CircleSvg} from "../../media/images/svg/circle-bg.svg";

const About = () => {
    return (
        <Wrapper classNameProps={classes.wrapper} backgroundUrl={backgroundImage}>
            <div className={classes.content}>
                <CircleSvg className={classes.contentBackground}/>

                <Title variant={'h1-small'}>За нас</Title>

                <p>
                    „СТМ - Търговище” ЕООД разполага с дългогодишен опит в сферата на безопасността и здравето при
                    работа. Дружеството е регистрирано през 2010 година, с цел да осигури на своите клиенти
                    висококачествени услуги, промотиращи здравословни и безопасни условия на труд. Натрупаният опит през
                    годините ни позволи да създадем силен и опитен екип от професионалисти в подпомагане на нашите
                    клиенти във всички аспекти на трудовата медицина. „СТМ – Търговище” ЕООД разполага със собствен офис
                    и модерно технологично оборудване за оптимизиране на работния процес.
                </p>

                <p>
                    „СТМ - Търговище” ЕООД е на разположение на работодателите за обслужване на техните работници и
                    служители в съответствие със изискванията на Закона за здравословни и безопасни условия на труд
                    (ЗЗБУТ) и Наредба № 3 за условията и реда за осъществяване на дейността на службите по трудова
                    медицина.
                </p>

                <p>
                    „Служба по трудова медицина – Търговище” ЕООД е единствената регистрирана служба на територията на
                    област Търговище.
                </p>
            </div>
        </Wrapper>
    );
}

export default About;

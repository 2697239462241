import React from 'react';
import classes from './ContactItem.module.scss';
import {joinClassNames} from "../../Utils";

interface ContactItemProps {
    svg: JSX.Element;
    color?: 'light' | 'dark';
    classNameProps?: string;
    text: string;
    url?: string;
    inactive?: boolean;
}

const ContactItem: React.FC<ContactItemProps> = ({
    svg,
    color = 'light',
    classNameProps = '',
    text,
    url,
    inactive = false,
}) => {
    const classNames = [
        classes.contactItem,
        color === 'dark' ? classes.dark : '',
        inactive ? classes.inactive : '',
        classNameProps,
    ];

    return (
        <>
            {inactive ? (
                <button className={joinClassNames(classNames)}>
                    {svg}

                    <span>{text}</span>
                </button>
            ) : (
                <a href={url} className={joinClassNames(classNames)}>
                    {svg}

                    <span>{text}</span>
                </a>
            )}
        </>
    )
};

export default ContactItem;

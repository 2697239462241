// Breakpoints
export const breakpoints = {
    lg: 1399,
    md: 1279,
    sm: 1023,
    xs: 767,
    xxs: 413,
}

// Blog
export const wpSiteUrl = 'stmblog24117076.wordpress.com';
export const wpBlogsUrl = `https://public-api.wordpress.com/rest/v1.1/sites/${wpSiteUrl}/posts`;

export const getBlogPostUrl = (slug: string) => `${wpBlogsUrl}/slug:${slug}`;

// Google maps
export const mapSrc = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2906.3456907215873!2d26.57112855751634!3d43.24417630676496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40af430cba07ebfd%3A0x59ae9772f502bb58!2z0KHQotCcLdCi0YrRgNCz0L7QstC40YnQtQ!5e0!3m2!1sbg!2sbg!4v1620324982739!5m2!1sbg!2sbg';

import React from 'react';
import classes from './Button.module.scss';
import { Link } from 'react-router-dom';
import {joinClassNames} from "../../Utils";

interface ButtonProps {
    type?: 'action' | 'anchor' | 'link';
    url?: string;
    theme?: 'white' | 'cyan';
    classNameProps?: string;
    onClick?: () => void;
    children: JSX.Element | string;
}

const Button: React.FC<ButtonProps> = ({
    type = 'anchor',
    url= '#',
    theme = 'cyan',
    classNameProps = '',
    onClick,
    children,
}) => {
    const classNames = [
        classes.button,
        theme === 'white' ? classes.white : classes.cyan,
        classNameProps,
    ];

    return (
        <>
            {type === 'action' && (
                <button
                    className={joinClassNames(classNames)}
                    onClick={onClick}
                >
                    {children}
                </button>
            )}
            {type === 'anchor' && (
                <a
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    className={joinClassNames(classNames)}>
                    {children}
                </a>
            )}
            {type === 'link' && (
                <Link
                    to={url}
                    className={joinClassNames(classNames)}
                >
                    {children}
                </Link>
            )}
        </>
    );
};

export default Button;

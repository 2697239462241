export const joinClassNames = (classNames: string[]): string => {
    const clearedClasses = classNames.filter(className => className.trim() !== '');
    return clearedClasses.join(' ');
}

export const setForWindowSize = (
    windowSize: number | undefined,
    minWidth: number = 0,
    maxWidth: number = 0
) => {
    if (!windowSize) return false;

    if (!!minWidth && !!maxWidth) {
        return minWidth >= windowSize && windowSize <= maxWidth;
    } else if (!!minWidth && !maxWidth) {
        return minWidth < windowSize;
    } else {
        return windowSize <= maxWidth;
    }
}

export const formattedDate = (dateString: string): string => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

import React from 'react';
import classes from './Contacts.module.scss';
import buildingSmallImage from './../../media/images/building-small.webp';
import Wrapper from "../../components/Wrapper/Wrapper";
import Title from "../../components/Title/Title";
import ContactItem from "../../components/ContactItem/ContactItem";
import {ReactComponent as PhoneSvg} from "../../media/images/svg/phone.svg";
import {ReactComponent as MobileSvg} from "../../media/images/svg/mobile.svg";
import {ReactComponent as EnvelopeSvg} from "../../media/images/svg/envelope.svg";
import {ReactComponent as MapSvg} from "../../media/images/svg/map.svg";
import {ReactComponent as WorkingHoursSvg} from "../../media/images/svg/business-time.svg";
import {ReactComponent as WebPageSvg} from "../../media/images/svg/browser.svg";
import {mapSrc} from "../../App-vars";
import {Spinner} from "../../components/Spinner/Spinner";

const Consultations = () => {
    return (
        <Wrapper>
            <div className={classes.root}>
                <div className={classes.map}>
                    <iframe src={mapSrc} allowFullScreen loading="lazy" title="Map" />

                    <Spinner />
                </div>

                <div className={classes.content}>
                    <div className={classes.contentInner}>
                        <Title variant={'h1-small'}>Контакти</Title>

                        <ul className={classes.listContacts}>
                            <li>
                                <ContactItem
                                    svg={<PhoneSvg />}
                                    text={'0601/88899'}
                                    url={'tel:0601/88899'}
                                />
                            </li>

                            <li>
                                <ContactItem
                                    svg={<MobileSvg />}
                                    text={'089 5414541'}
                                    url={'tel:0895414541'}
                                />
                            </li>

                            <li>
                                <ContactItem
                                    svg={<MobileSvg />}
                                    text={'087 8959694'}
                                    url={'tel:0878959694'}
                                />
                            </li>

                            <li>
                                <ContactItem
                                    svg={<EnvelopeSvg />}
                                    text={'stm_targovishte@abv.bg'}
                                    url={'mailto:stm_targovishte@mail.bg'}
                                />
                            </li>

                            <li>
                                <ContactItem
                                    svg={<MapSvg />}
                                    text={'ул. Г.Бенковски 1, вх. А, ет. 2, офис 3'}
                                    url={'https://goo.gl/maps/kNoViyepRFr77BEz8'}
                                />
                            </li>

                            <li>
                                <ContactItem
                                    svg={<WorkingHoursSvg />}
                                    text={'08:00 часа - 17:00 часа'}
                                    inactive={true}
                                />
                            </li>

                            <li>
                                <ContactItem
                                    svg={<WebPageSvg />}
                                    text={'www.stmtg.org'}
                                    inactive={true}
                                />
                            </li>
                        </ul>

                        <div className={classes.image}>
                            <img src={buildingSmallImage} alt="Офис сграда"/>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

export default Consultations;

import React from 'react';
import classes from './Home.module.scss';
import Wrapper from "../../components/Wrapper/Wrapper";
import {HomeLayout} from "../../components/CanvasAnimation/HomeLayout";

const Home = () => {
    return (
        <Wrapper
            classNameProps={classes.root}
        >
            <HomeLayout />
        </Wrapper>
    );
}

export default Home;

import React, {useEffect, useState} from 'react';
import {useLocation, useHistory} from "react-router-dom";
import Service from "../../components/Service/Service";
import {ReactComponent as SubscriptionSvg} from "../../media/images/svg/subscription.svg";
import {ReactComponent as RateSvg} from "../../media/images/svg/rate.svg";
import {ReactComponent as BookSvg} from "../../media/images/svg/book.svg";
import {ReactComponent as DocumentSvg} from "../../media/images/svg/document.svg";
import {ReactComponent as StethoscopeSvg} from "../../media/images/svg/stethoscope.svg";
import {ReactComponent as PeopleSvg} from "../../media/images/svg/people.svg";
import {ReactComponent as FilesSvg} from "../../media/images/svg/files.svg";

export enum ServicesEnum {
    Subscription = 'subscription',
    Grade = 'grade',
    Instructions = 'instructions',
    Documents = 'documents',
    MedCheck = 'medCheck',
    Organise = 'organise',
    MoreDocuments = 'moreDocuments',
}

const ServicesContent = () => {
    const {pathname, search} = useLocation();
    const history = useHistory();
    const [activeService, setActiveService] = useState('');

    useEffect(() => {
        setActiveService(getSearchParam(search));
    }, [search]);

    const handleCloseService = () => {
        setActiveService('');
        history.push(pathname);
    }
    const getSearchParam = (query: string) => {
        return query.split('?name=')[1];
    }

    const handleOnClick = (query: string) => {
        history.push(`${pathname}?name=${query}`)
    }

    return (
        <>
            <Service
                title={'Абонаментно обслужване'}
                svg={<SubscriptionSvg/>}
                serviceActive={activeService === ServicesEnum.Subscription}
                onClick={() => handleOnClick(ServicesEnum.Subscription)}
                closeService={handleCloseService}
            >
                <ul>
                    <li>
                        Консултиране и подпомагане на работодателя за прилагане на превантивните подходи за осигуряване
                        на здравословни и безопасни условия на труд и за въвеждане на принципа за постоянното
                        подобряване на управлението на дейностите по здраве и безопасност
                    </li>

                    <li>
                        Оценка на условията на труд и на работните места
                    </li>

                    <li>
                        Разработване на мерки за отстраняване и намаляване на риска за здравето и безопасността при
                        работа, препоръки за работодателя за преустройството на работното място, организацията на труда
                        и трудовото натоварване на работещи
                    </li>

                    <li>
                        Наблюдение на здравословното състояние на работниците и служителите и анализирането му във
                        връзка с извършваната работа
                    </li>

                    <li>
                        Програми за обучение на ръководния персонал, на работещите и на техните представители по
                        правилата за осигуряване на здраве и безопасност при работа и спазването на изискванията за
                        безопасна работна практика
                    </li>

                    <li>
                        Организират и провеждат обучението на работещите по правилата за първа помощ, самопомощ и
                        взаимопомощ във връзка с конкретните опасности на работното място
                    </li>

                    <li>
                        Участват в реализирането на програми за промоция на здравето на работещите на работното място,
                        отстраняване на рисковите фактори на начина на живот, опазване и укрепване на работоспособността
                        и преодоляване на стреса при работа
                    </li>

                    <li>
                        Подпомагат работодателите при разработването на правила, норми и инструкции в предприятията за
                        осигуряване на здраве и безопасност при работа и дават съвети на работещите за правилното им
                        прилагане
                    </li>

                    <li>
                        Консултират и подпомагат комитетите и групите по условия на труд при осъществяване на дейността
                        им
                    </li>

                    <li>
                        Изготвяне на списък с необходимите медицински специалисти, при провеждане на предварителни
                        медицински прегледи за новоназначени служители
                    </li>

                    <li>
                        Заключения за пригодността на работника да изпълнява дадения вид работа при новоназначени
                        служители въз основа на данните от Картата за предварителен медицински преглед
                    </li>

                    <li>
                        Изготвяне на списък с необходимите медицински специалисти, при провеждане на периодичните
                        профилактични прегледи
                    </li>

                    <li>
                        Заключения за пригодността на работещия да изпълнява дадения вид работа въз основа на
                        извършените профилактични медицински прегледи
                    </li>

                    <li>
                        Предоставяне на информация на служителите относно здравните рискове, свързани с работата и за
                        резултатите от проведените медицински прегледи и изследвания по подходящ начин и след
                        съгласуване с работодателя
                    </li>

                    <li>
                        Изготвяне на обобщени анализи за здравословното състояние на работниците и служителите
                    </li>

                    <li>
                        Водене на лични здравни досиета за здравословното състояние на служителите
                    </li>

                    <li>
                        Оценка на физическия труд и разработване на физиологични режими на труд и почивка по време на
                        работа
                    </li>

                    <li>
                        Участие в разследването на трудови злополуки и професионални заболявания
                    </li>
                </ul>
            </Service>

            <Service
                title={'Оценка на риска'}
                svg={<RateSvg/>}
                serviceActive={activeService === ServicesEnum.Grade}
                onClick={() => handleOnClick(ServicesEnum.Grade)}
                closeService={handleCloseService}
            >
                <p>
                    Оценка на работните процеси, оборудването, помещенията, работните места, организацията на труда,
                    факторите на работната среда и трудовия процес и определяне на изложените на риск лица. Разработване
                    на препоръки и мерки за предотвратяване, отстраняване или намаляване на установения риск.
                </p>
            </Service>

            <Service
                title={'Организация на инструктажите'}
                svg={<BookSvg/>}
                serviceActive={activeService === ServicesEnum.Instructions}
                onClick={() => handleOnClick(ServicesEnum.Instructions)}
                closeService={handleCloseService}
            >
                <p>
                    Вътрешно фирмени заповеди, инструкции и програми за инструктаж
                </p>
            </Service>

            <Service
                title={'Документи по ЗБУТ във фирмата'}
                svg={<DocumentSvg/>}
                serviceActive={activeService === ServicesEnum.Documents}
                onClick={() => handleOnClick(ServicesEnum.Documents)}
                closeService={handleCloseService}
            >
                <ul>
                    <li>
                        Програма за оценяване на риска
                    </li>

                    <li>
                        Оценка на условията на труд и професионалните рискове
                    </li>

                    <li>
                        Карта за оценка на условията на труд и професионалният риск
                    </li>

                    <li>
                        Оценка на професионалния риск по работни места
                    </li>

                    <li>
                        Програма за предотвратяване на професионалния риск
                    </li>

                    <li>
                        Физиологичен режим на труд и почивка
                    </li>

                    <li>
                        Полагащи се лични предпазни средства и работно облекло
                    </li>

                    <li>
                        Списък на подлежащите на задължителни периодични медицински прегледи
                    </li>

                    <li>
                        Заповед за лице, което представлява управителя пред контролните органи
                    </li>

                    <li>
                        Сертификат за лице, което провежда инструктажи
                    </li>

                    <li>
                        Документи за Група/Комитет по условия на труд
                    </li>

                    <li>
                        Вътрешно фирмени заповеди, инструкции и програми за инструктаж
                    </li>

                    <li>
                        Споразумение по чл. 18 от ЗБУТ за фирми работещи съвместно на обща територия
                    </li>
                </ul>
            </Service>

            <Service
                title={'Организиране на профилактични медицински прегледи'}
                svg={<StethoscopeSvg/>}
                serviceActive={activeService === ServicesEnum.MedCheck}
                onClick={() => handleOnClick(ServicesEnum.MedCheck)}
                closeService={handleCloseService}
            >
                <p>
                    Консултиране и подпомагане на работодателя за вида на медицинските прегледи и изследвания при
                    организирането на предварителните медицински прегледи за преценка на пригодността на работещите при
                    постъпване на работа. Консултиране и подпомагане на работодателя за вида на медицинските прегледи и
                    изследвания при организирането на периодичните медицински прегледи за ранно откриване на измененията
                    в организма в резултат от въздействия на работната среда и трудовия процес.
                </p>
            </Service>

            <Service
                title={'Фактори на работната среда и ел.безопасност'}
                svg={<PeopleSvg/>}
                serviceActive={activeService === ServicesEnum.Organise}
                onClick={() => handleOnClick(ServicesEnum.Organise)}
                closeService={handleCloseService}
            >
                <p>
                    Специализирани измервания на факторите на работната среда – осветление, микроклимат, шум,
                    запрашеност по работни места, контрол на “контур-фаза” защитен проводник, заземяване, мълниезащита,
                    контрол на вентилационни и климатични системи.
                </p>
            </Service>

            <Service
                title={'Други документи'}
                svg={<FilesSvg/>}
                serviceActive={activeService === ServicesEnum.MoreDocuments}
                onClick={() => handleOnClick(ServicesEnum.MoreDocuments)}
                closeService={handleCloseService}
            />
        </>
    );
}

export default ServicesContent;

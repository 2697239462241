import {AccordionProps} from "../../components/Accordion/Accordion";
import React from "react";

export enum AccordionNames {
    Acc1 = 'acc-1',
    Acc2 = 'acc-2',
    Acc3 = 'acc-3',
    Acc4 = 'acc-4',
    Acc5 = 'acc-5',
    Acc6 = 'acc-6',
    Acc7 = 'acc-7',
}

export interface AccordionsContentModel {
    name: string;
    content?: JSX.Element;
}

export const accordionsProps: AccordionProps[] = [
    {
        title: 'ЗБУТ',
        name: AccordionNames.Acc1,
    },
    {
        title: 'Длъжностни лица, провеждащи инструктажи по безопасност и здраве при работа',
        name: AccordionNames.Acc2,
    },
    {
        title: 'Представителите на КУТ/ГУТ',
        name: AccordionNames.Acc3,
    },
    {
        title: 'Работещи с изискване за правоспособност за работа',
        name: AccordionNames.Acc4,
    },
    {
        title: 'Работещи в дейности с опасност за тях и други лица',
        name: AccordionNames.Acc5,
    },
    {
        title: 'Първа долекарска помощ',
        name: AccordionNames.Acc6,
    },
    {
        title: 'Обучение за Ръчна работа с тежести',
        name: AccordionNames.Acc7,
    },
];

export const accordionsContent: AccordionsContentModel[] = [
    {
        name: AccordionNames.Acc1,
        content: (
            <>
                <h4>(ЗБУТ) Длъжностни лица, които ръководят и управляват трудовите процеси</h4>

                <p>
                    Удостоверения на длъжностните лица, които ръководят и управляват трудовите процеси, съгл. чл. 6, ал.
                    1, т.1 от Наредба № РД-07-2 на МТСП от 16.12.2009 г.
                </p>
            </>
        ),
    },
    {
        name: AccordionNames.Acc2,
        content: (
            <>
                <h4>Длъжностни лица, провеждащи инструктажи по безопасност и здраве при работа</h4>

                <p>
                    Удостоверения на длъжностните лица, провеждащи инструктажите по безопасност и здраве при работа,
                    съгл. чл. 6, ал. 1, т.2 и т. 3 от Наредба № РД-07-2 на МТСП от 16.12.2009 г.
                </p>
            </>
        ),
    },
    {
        name: AccordionNames.Acc3,
        content: (
            <>
                <h4>Представителите на КУТ/ГУТ</h4>

                <p>
                    Удостоверение на членове на КУТ/ГУТ, съгл. чл. 9 от Наредба №4 от 03.11.1998 г. и чл. 6, ал. 1, т. 4
                    от Наредба № РД-07-2 на МТСП от 16.12.2009 г. – първоначално обучение
                </p>

                <p>
                    Удостоверение на членове на КУТ/ГУТ, съгл. чл. 12 от Наредба №4 от 03.11.1998 г. и чл. 6, ал. 1, т.
                    4 от Наредба № РД-07-2 на МТСП от 16.12.2009 г. – ежегодно обучение
                </p>
            </>
        ),
    },
    {
        name: AccordionNames.Acc4,
        content: (
            <>
                <h4>Работещи с изискване за правоспособност за работа</h4>

                <p>Удостоверение съгласно чл.6, ал.1, т.5 от Наредба № РД-07-2 на МТСП от 16.12.2009 г.</p>
            </>
        ),
    },
    {
        name: AccordionNames.Acc5,
        content: (
            <>
                <h4>Работещи в дейности с опасност за тях и други лица</h4>

                <p>Удостоверение съгласно чл.6, ал.1, т.6 от Наредба № РД-07-2 на МТСП от 16.12.2009 г</p>
            </>
        ),
    },
    {
        name: AccordionNames.Acc6,
        content: (
            <>
                <h4>Първа долекарска помощ</h4>

                <p>
                    Удостоверение за преминато обучение по оказване на първа долекарска медицинска помощ, самопомощ и
                    взаимопомощ на пострадали работещи, съгл. чл. 20, ал.1 и ал.2 от ЗЗБУТ и Правила за оказване на
                    първа долекарска помощ утвърдени със заповед № РД 09-410/ 1994г.
                </p>
            </>
        ),
    },
    {
        name: AccordionNames.Acc7,
    },
];

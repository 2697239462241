import React from 'react';
import classes from './Nav.module.scss';
import animationClasses from './nav.animations.module.scss';
import {Link, useLocation} from 'react-router-dom';
import {Locations} from "../../App";
import {CSSTransition} from 'react-transition-group';
import {useContextState} from "../../ContextState";
import {joinClassNames} from "../../Utils";

const Nav = () => {
    const {settings, blog, setSettings} = useContextState();
    const location = useLocation();

    const activeLink = (path: string): string | undefined =>
        path === location.pathname ? classes.linkActive : undefined;

    const handleCloseNav = () => setSettings({navOpen: false});

    const menuButtonDark = () => {
        const menuDarkThemePages = [Locations.Home];
        return !!menuDarkThemePages.find(locationPage => locationPage === location.pathname);
    }

    const classNames = [
        classes.menuButton,
        settings.navOpen ? classes.active : '',
        menuButtonDark() && !settings.navOpen ? classes.darkButton : '',
    ]

    return (
        <>
            <button
                className={joinClassNames(classNames)}
                onClick={() => setSettings({navOpen: !settings.navOpen})}
            >
                <p>МЕНЮ</p>

                <div>
                    <span />
                </div>
            </button>

            <CSSTransition
                in={settings.navOpen}
                timeout={500}
                classNames={animationClasses}
                unmountOnExit
            >
                <nav className={classes.nav}>
                    <div className={classes.bar}>
                        <h6>Меню</h6>
                    </div>

                    <div className={classes.links}>
                        <ul>
                            <li
                                className={activeLink(Locations.Home)}
                                onClick={handleCloseNav}
                            >
                                <Link to={Locations.Home}>Начало</Link>
                            </li>

                            <li
                                className={activeLink(Locations.About)}
                                onClick={handleCloseNav}
                            >
                                <Link to={Locations.About}>За нас</Link>
                            </li>

                            <li
                                className={activeLink(Locations.Services)}
                                onClick={handleCloseNav}
                            >
                                <Link to={Locations.Services}>Услуги</Link>
                            </li>

                            <li
                                className={activeLink(Locations.Courses)}
                                onClick={handleCloseNav}
                            >
                                <Link to={Locations.Courses}>Обучение</Link>
                            </li>

                            <li
                                className={activeLink(Locations.Consultations)}
                                onClick={handleCloseNav}
                            >
                                <Link to={Locations.Consultations}>Консултации</Link>
                            </li>

                            {!!blog.posts && (
                                <li
                                    className={activeLink(Locations.Blog)}
                                    onClick={handleCloseNav}
                                >
                                    <Link to={Locations.Blog}>Новини</Link>
                                </li>
                            )}

                            <li
                                className={activeLink(Locations.Contacts)}
                                onClick={handleCloseNav}
                            >
                                <Link to={Locations.Contacts}>Контакти</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </CSSTransition>
        </>
    );
};

export default Nav;

import React, {useState} from 'react';
import classes from './Courses.module.scss';
import coursesImage from './../../media/images/courses-bg.webp';
import coursesBg from "../../media/images/svg/courses-bg.svg";
import Wrapper from "../../components/Wrapper/Wrapper";
import Title from "../../components/Title/Title";
import BoxAccordions from "../../components/BoxAccordions/BoxAccordions";
import {
    AccordionNames,
    accordionsContent,
    accordionsProps
} from "./CourseAccordionsContent";

const Courses = () => {
    const [activeCourse, setActiveCourse] = useState<string>(AccordionNames.Acc1);

    return (
        <Wrapper
            backgroundUrl={coursesImage}
            classNameProps={classes.root}
        >
            <div className={classes.info}>
                <div className={classes.infoInner}>
                    <Title variant={'h1-small'}>Обучение</Title>
                </div>
            </div>

            <BoxAccordions
                accordions={accordionsProps}
                accordionsContent={accordionsContent}
                activeAccordion={activeCourse}
                toggleAccordion={(name: string) => setActiveCourse(name)}
            />

            <figure className={classes.overlayBg} style={{backgroundImage: `url(${coursesBg})`}} />
        </Wrapper>
    );
}

export default Courses;

import React from 'react';
import classes from './Figure.module.scss';
import figureSvg from './../../media/images/svg/figure.svg';

export interface FigureProps {
    side?: 'left' | 'right';
}

const Figure: React.FC<FigureProps> = ({
    side = 'right',
}) => {
    return (
        <figure className={`${classes.root} ${side === 'left' ? classes.left : ''}`}>
            <img src={figureSvg} alt="Figure" />
        </figure>
    );
};

export default Figure;

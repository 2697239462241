import React from 'react';
import classes from './Service.module.scss';
import Title from "../Title/Title";
import {CSSTransition} from "react-transition-group";
import animationClasses from "./service.animations.module.scss";

export interface ServiceProps {
    children?: JSX.Element | string | (JSX.Element | string)[];
    theme?: 'white' | 'cyan';
    title: string;
    svg: JSX.Element;
    onClick: () => void;
    closeService: () => void;
    serviceActive: boolean;
}

const Service: React.FC<ServiceProps> = ({
    children,
    title,
    svg,
    onClick,
    closeService,
    serviceActive,
}) => {
    return (
        <>
            <div className={`${classes.root} ${serviceActive ? classes.active : ''} ${!children ? classes.disabled : ''}`} onClick={onClick}>
                {svg}

                <h4>{title}</h4>
            </div>

            <CSSTransition
                in={serviceActive}
                timeout={300}
                classNames={animationClasses}
                unmountOnExit
            >
                <div className={classes.content}>
                    <button
                        className={classes.closeButton}
                        onClick={closeService}
                    >
                    </button>

                    <div className={classes.contentInner}>
                        <Title
                            variant={'h4'}
                            color={'white'}
                        >
                            {title}
                        </Title>

                        {children}
                    </div>
                </div>
            </CSSTransition>
        </>
    );
};

export default Service;

import React from 'react';
import classes from './BlogItem.module.scss';
import { BlogPostItemProps } from "../../interfaces";
import {ReactComponent as ImagePlaceholderSvg} from "../../media/images/svg/image-placeholder.svg";
import { Link } from 'react-router-dom';
import {Locations} from "../../App";

export const BlogItem: React.FC<BlogPostItemProps> = ({
    id,
    slug,
    title,
    excerpt,
    thumbnail,
    date,
}: BlogPostItemProps) => {
    return (
        <div className={classes.root}>
            <Link to={`${Locations.Blog}/${slug}`} className={classes.thumbnail} style={{backgroundImage: `url(${thumbnail})`}}>
                {!thumbnail && <ImagePlaceholderSvg />}
            </Link>

            <span className={classes.meta}>{date}</span>

            <h4>
                <Link to={`${Locations.Blog}/${slug}`}>
                    {title}
                </Link>
            </h4>
        </div>
    );
}
